<template>
    <nav class="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0 active" id="nav">
        <div class="container-fluid d-flex flex-column p-0">
            
            <a class="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0" href="#">
                <div class="sidebar-brand-icon"> <img src="../assets/logo.png" style="width: 105%;"> </div>
                <div class="sidebar-brand-text mx-3"></div>
            </a>
            <hr class="sidebar-divider my-0">
            <ul class="nav navbar-nav text-light w-100 pl-sm-1" :class="{'pl-2': $vuetify.breakpoint.smAndDown}" id="accordionSidebar">
                <li class="nav-item" v-if="user_type == 'A' || user_type == 'K' || permissions.includes('index')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/"><i class="fas fa-home"></i> &nbsp; <span> {{ navLanguage[language].home }} </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || permissions.includes('users')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/users"><i class="fas fa-users"></i> &nbsp; <span> {{ navLanguage[language].users }} </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || permissions.includes('engineers')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/engineers"><i class="fas fa-user-nurse"></i> &nbsp; <span> {{ navLanguage[language].supervisors }} </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || user_type == 'K' || permissions.includes('employees')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/employees"><i class="fas fa-users"></i> &nbsp; <span> {{ navLanguage[language].employees }} </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || permissions.includes('attendance')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/attendance"><i class="fas fa-hourglass"></i> &nbsp; <span> {{ navLanguage[language].attendance }} </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || user_type == 'K' || permissions.includes('give_salary')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/give_salary"><i class="fas fa-money-bill-alt"></i> &nbsp; <span> {{ navLanguage[language].give_salary }} </span></router-link></li>
                <li class="nav-item"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/loan_accomodation"><i class="fas fa-receipt"></i> &nbsp; <span style="font-size: 13.1px;"> Loan <br> Accomodation </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || permissions.includes('debtTransaction')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/debtTransaction"><i class="fas fa-receipt"></i> &nbsp; <span> {{ 'Employee Debts' }} </span></router-link></li>
                <li class="nav-item"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/salary_list"><i class="fas fa-hand-holding-usd"></i> &nbsp; <span> Salary List </span></router-link></li>
                <li class="nav-item"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/office"><i class="fas fa-hand-holding-usd"></i> &nbsp; <span> Office </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || permissions.includes('foodList')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/foodList"><i class="fas fa-receipt"></i> &nbsp; <span> {{ 'Food List' }} </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || permissions.includes('staff_expenses')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/staff_expenses"><i class="fas fa-money-bill-wave"></i> &nbsp; <span> Staff Expenses </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || permissions.includes('foodGroups')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/foodGroups"><i class="fas fa-receipt"></i> &nbsp; <span> {{ 'Staff Foods' }} </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || permissions.includes('employeeMonthDetail')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/employee_month_detail"><i class="fas fa-receipt"></i> &nbsp; <span> {{ 'Employee Month Detail' }} </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || permissions.includes('cabinas')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/cabina"><i class="fas fa-warehouse"></i> &nbsp; <span> {{ 'Cabina' }} </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || permissions.includes('workProjects')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/work_projects"><i class="fas fa-city"></i> &nbsp; <span> {{ 'Projects' }} </span></router-link></li>
                <li class="nav-item" v-if="user_type == 'A' || permissions.includes('notifications')"><router-link :class="{'pl-1 ml-0': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/notifications"><i class="fas fa-bell"></i> &nbsp; <span> {{ 'Notifications' }} </span></router-link></li>
                <!-- <li class="nav-item"><router-link class="nav-link" to="/employeeProfile"><i class="fas fa-users"></i> &nbsp; <span>Employee Profile</span></router-link></li> -->
                <li class="nav-item dropdown-submenu" data-toggle="dropdown" role="button"> 
                    <span class="nav-link multi-language"><i class="fa fa-file"></i> &nbsp; {{ navLanguage[language].reports }} </span>
                    <ul class="dropdown-menu bg-primary" :class="{'pl-0': $vuetify.breakpoint.smAndDown}">
                        <li  v-if="user_type == 'A' || permissions.includes('staffReport')"><router-link :class="{'pl-0 ml-n4': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/reports/staff"><i class="fas fa-users"></i> &nbsp; <span> {{ navLanguage[language].staffs }} </span></router-link></li>
                        <li  v-if="user_type == 'A' || permissions.includes('engineerReport')"><router-link :class="{'pl-0 ml-n4': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/reports/engineer"><i class="fas fa-user-nurse"></i> &nbsp; <span> {{ navLanguage[language].supervisors }} </span></router-link></li>
                        <li ><router-link :class="{'pl-0 ml-n4': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/reports/employee"><i class="fas fa-users"></i> &nbsp; <span> {{ navLanguage[language].employees }} </span></router-link></li>
                        <li  v-if="user_type == 'A' || permissions.includes('givedSalaryReport')"><router-link :class="{'pl-0 ml-n4': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/reports/givedSalary"><i class="fas fa-money-bill-alt"></i> &nbsp; <span> {{ navLanguage[language].give_salary_report }} </span></router-link></li>
                        <li  v-if="user_type == 'A' || permissions.includes('topEmployeeReport')"><router-link :class="{'pl-0 ml-n4': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/reports/topEmployees"><i class="fas fa-chevron-up"></i> &nbsp; <span> {{ navLanguage[language].top_employees }} </span></router-link></li>
                        <li  v-if="user_type == 'A' || permissions.includes('staffListReport')"><router-link :class="{'pl-0 ml-n4': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/reports/staffList"><i class="fas fa-list"></i> &nbsp; <span> {{ navLanguage[language].staff_list_report }} </span></router-link></li>
                        <li  v-if="user_type == 'A' || permissions.includes('monthlySalaryReport')"><router-link :class="{'pl-0 ml-n4': $vuetify.breakpoint.smAndDown}" class="nav-link multi-language" to="/reports/MonthlySalary"><i class="fas fa-hand-holding-usd"></i> &nbsp; <span> {{ navLanguage[language].salary_list }} </span></router-link></li>
                    </ul>
                </li>
            </ul>
            <div class="text-center d-none d-md-inline">
                <button class="btn rounded-circle border-0"  id="sidebarToggle" type="button"></button>
            </div>
        </div>
    </nav>
</template>
<script>
    import $ from "jquery";
    import navLanguage from '../languages/nav.js';
    import { Bus } from '../Bus.js';
    export default {
        name: "navbar",
        data() {
            return {
                permissions: [],
                isActive: true,
                navLanguage,
                language: "English",
                user_type: ''
            }
        },
        created() {
            this.$http.post("/getLoggedInfo").then(({data}) => {
                this.user_type = data.type;
                if(data.type == 'U'){
                    this.permissions = ["index" ,"attendance", "employeeReport", "engineerReport", "givedSalaryReport", "staffReport", "staffListReport", "topEmployeeReport", "debtTransaction"];
                } else if(data.type == 'E'){
                    this.permissions = ["index" ,"attendance", "staffReport", "staffListReport", "give_salary", "notifications"];
                }
            });
        },
        mounted() {
            document.getElementById("sidebarToggleTop").classList.remove('d-md-none');
            document.getElementById("sidebarToggleTop").style.display = 'inline-block !important';

            $("#sidebarToggle, #sidebarToggleTop").on("click", function() {
                $("#nav").toggleClass("active");
            });

            Bus.$on("languageChanged", (data) => {
                this.language = data;
                if(["Kurdish", "Arabic"].includes(data)){
                    $(".multi-language").addClass("text-right");
                } else {
                    $(".multi-language").removeClass("text-right");
                }
            });
        }
    }
</script>

<style scoped>
    nav {
        display: none;
    }
    nav.active {
        display: flex;
    }
    .nav-link{
        width: 100% !important;
    }
</style>