import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// import index from "../components/index.vue";
import engineers from "../components/engineers.vue";
// import employees from "../components/employees.vue";
import users from "../components/users.vue";
import attendance from "../components/attendance.vue";
// import debtTransaction from "../components/debtTransaction.vue";
//import employeeProfile from "../components/employeeProfile.vue";
// import give_salary from "../components/give_salary.vue";
import login from "../components/login.vue";
import error404 from "../components/error404.vue";
// import staffReport from "../components/reports/staff.vue";
import engineerReport from "../components/reports/engineer.vue";
// import employeeReport from "../components/reports/employee.vue";
import givedSalaryReport from "../components/reports/givedSalary.vue";
import topEmployeesReport from "../components/reports/topEmployees.vue";
// import staffListReport from "../components/reports/staffList.vue";
// import monthlySalary from "../components/reports/monthlySalary.vue";
// import cabina from "../components/cabina.vue";
import notifications from "../components/notifications.vue";
// import salary_list from "../components/salary_list.vue";
// import total_salary_list from "../components/total_salary_list.vue"
import staff_expenses from "../components/staff_expenses.vue";
// import staff_expenses_report from "../components/staff_expenses_report.vue";
import foodList from "../components/foodList.vue";
import foodGroups from "../components/foodGroups.vue";
import employee_month_detail from "../components/employee_month_detail.vue";
// import loan_accomodation from "../components/loan_accomodation.vue";
// import printExpiredPassports from "../components/printExpiredPassport.vue";

const routes = [
  { path: "/", component: () => import("../components/index.vue")},
  { path: "/engineers", component: engineers },
  { path: "/employees", component: () => import("../components/employees.vue")},
  { path: "/work_projects", component: () => import("../components/work_projects.vue")},
  { path: "/work_projects_report", component: () => import("../components/work_projects_report.vue")},
  { path: "/users", component: users },
  { path: "/attendance", component: attendance },
  { path: "/debtTransaction", component: () => import("../components/debtTransaction.vue")},
  //{ path: "/employeeProfile", component: employeeProfile },
  //{ path: "/employeeProfile/:month/:year/:phone", component: employeeProfile },
  { path: "/give_salary", component: () => import("../components/give_salary.vue")},
  { path: "/login", component: login },
  { path: "/salary_list", component: () => import("../components/salary_list.vue") },
  { path: "/office", component: () => import("../components/office.vue") },
  { path: "/total_salary_list/:month/:year/:dollar_price/:special", component: () => import("../components/total_salary_list.vue")},
  { path: "/print_expired_passports", component: () => import("../components/printExpiredPassport.vue")},
  { path: "/staff_expenses", component: staff_expenses },
  { path: "/staff_expenses_report", component: () => import("../components/staff_expenses_report.vue")},
  { path: "/foodList", component: foodList },
  { path: "/foodGroups", component: foodGroups },
  { path: "/employee_month_detail", component: employee_month_detail },
  { path: "/loan_accomodation", component: () => import("../components/loan_accomodation.vue")},
  { path: "/cabina", component: () => import("../components/cabina.vue")},
  { path: "/notifications", component: notifications },
  { path: "/reports/staff", component: () => import("../components/reports/staff.vue")},
  { path: "/reports/engineer", component: engineerReport },
  { path: "/reports/employee", component: () => import("../components/reports/employee.vue")},
  { path: "/reports/givedSalary", component: givedSalaryReport },
  { path: "/reports/topEmployees", component: topEmployeesReport },
  { path: "/reports/staffList", component: () => import("../components/reports/staffList.vue")},
  { path: "/reports/monthlySalary", component: () => import("../components/reports/monthlySalary.vue")},
  { path: "/zeroList", component: () => import("../components/zeroList.vue") },
  { path: "*", component: error404 }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
